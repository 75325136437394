<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="bg-white pa-10-20 radius-20 full-height flex-column"
			>
				<div
					class="pa-10-20 under-line size-px-20 text-center color-blue-mafia font-weight-bold"
				>{{ $language.cartel_new.title_support_history_detail }} <!-- 후원 상세 --></div>

				<div
					class="mt-10 size-px-14 full-height flex-column"
				>
					<div>
						<div class="under-line pb-10">
							<div class="size-px-16 font-weight-bold">{{ $language.point_result.title_information }} <!-- 정보 --></div>
							<div class="mt-10 justify-space-between">
								<span>{{ $language.point.txt_heart }} <!-- 불렛 --></span>
								<span>{{ item_info.spnsh_point_quantity | makeComma }}</span>
							</div>
							<div class="mt-10 justify-space-between">
								<span>{{ $language.add.title_sponsor}} <!-- 후원자 --></span>
								<span>{{ item_info.spnsh_member_nickname }}</span>
							</div>
							<div class="mt-10 justify-space-between">
								<span>{{ $language.point_result.title_date }} <!-- 일시 --></span>
								<span>{{ item_info.spnshday }}</span>
							</div>
						</div>
						<div class="mt-10">
							<div class="size-px-16 font-weight-bold">{{ $language.add.title_support_message }} <!-- 응원 메시지 --></div>
							<div class="mt-10 " :inner-html.prop="item_info.spnsh_message | nl2br">
							</div>
						</div>
					</div>

					<div
						class="mt-auto btn_area pt-30"
					>
						<button
							class="btn_l btn_fill_blue"
							@click="$emit('click')"
						>{{ $language.point_result.btn_confirm }} <!-- 확인 --></button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'CartelSupportHistoryDetail'
		, components: {PopupLayer}
		, props: ['user', 'item_info']
		, data: function(){
			return {

			}
		}
		, methods: {

		}
		, created() {
			console.log(this.item_info)
		}
	}
</script>